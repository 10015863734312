import {createSlice} from "@reduxjs/toolkit";
import UserSession from "../../services/UserSession";

let swap = function (array, index1, index2) {
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;
    return array;
};

let permutationHeap = function (array, callback, n) {
    n = n || array.length;
    if (n === 1) {
        callback(array);
    } else {
        for (let i = 1; i <= n; i++) {
            permutationHeap(array, callback, n - 1);
            if (n % 2) {
                swap(array, 0, n - 1);
            } else {
                swap(array, i - 1, n - 1);
            }
        }
    }
};

const setTeams = (players, iteration) => {
    const numberOfTeams = players.length / 2;
    const combinations = [];

    permutationHeap(players, (a) => {
        const teams = [];
        for (let i = 0; i < numberOfTeams; i++) {
            teams[i] = {
                team: i + 1,
                players: []
            };
        }
        for (let j = 1; j <= a.length; j++) {
            const team = teams[Math.ceil(j / 2) - 1];
            const player = a[j - 1];
            team.players.push(player);
        }

        const combination = teams.map((t) => {
            return {team: t.team, players: t.players.slice().sort((a, b) => a.id - b.id)}
        }).slice().sort((c, d) => c.players[0].id - d.players[0].id);

        let exists = false;
        combinations.forEach((c) => {
            const j = JSON.stringify(combination.map((t) => t.players.map(p => p.id)));
            const k = JSON.stringify(c.map((t) => t.players.map(p => p.id)));

            if (j === k) {
                exists = true;
            }
        });

        if (!exists) {
            combinations.push(combination);
        }
    });

    combinations.sort((a, b) => {
        const x = a.reduce((acc, t) => acc + t.players.reduce((acc2, p) => acc2 + p.id, ''), '');
        const y = b.reduce((acc, t) => acc + t.players.reduce((acc2, p) => acc2 + p.id, ''), '');

        return parseInt(x) - parseInt(y);
    });

    return combinations;
};

const gameSetupSlice = createSlice({
    name: 'gameSetup',
    initialState: {
        currentStep: 1,
        positionBeingSelected: null,
        loading: false,
        setup: {
            players: [],
            numberOfPlayers: 0,
            cardsToDeal: null,
            totalDecks: 5,
            totalPickupCards: 6,
            red3s: 1,
            maxWildCards: 3,
            addToCompletedCanastas: 1,
            numberOfCanastas: 1,
            askToGoOut: 1,
            black3s: 2,
            swipeCards: 2,
        },
        error: null
    },
    reducers: {
        setGameSetup: {
            reducer(state, action) {
                if (action.payload.gameSetup !== null) {
                    state.currentStep = action.payload.gameSetup.currentStep;
                    state.loading = action.payload.gameSetup.loading;
                    state.setup = action.payload.gameSetup.setup;
                    state.error = action.payload.gameSetup.error;
                } else {
                    state.currentStep = 1;
                }
            },
            prepare(gameSetup) {
                return {payload: {gameSetup}};
            }
        },
        goToNextStep: {
            reducer(state, action) {
                state.currentStep = state.currentStep + 1;
                state.error = null;
            },
        },
        goToPreviousStep: {
            reducer(state, action) {
                if (state.positionBeingSelected) {
                    state.positionBeingSelected = null;
                } else {
                    state.currentStep = state.currentStep > 1 ? state.currentStep - 1 : 1;
                }
                state.error = null;
            },
        },
        setPositionBeingSelected: {
            reducer(state, action) {
                state.positionBeingSelected = action.payload.positionBeingSelected;
            },
            prepare(positionBeingSelected) {
                return {payload: {positionBeingSelected}};
            }
        },
        cancel: {
            reducer(state, action) {
                state.currentStep = 1;
                state.error = null;
                state.loading = false;
                state.setup.players = [];
                state.setup.cardsToDeal = null;
                state.setup.totalDecks = 5;
                state.setup.totalPickupCards = 6;
                state.setup.red3s = 1;
                state.setup.maxWildCards = 3;
                state.setup.addToCompletedCanastas = 1;
                state.setup.numberOfCanastas = 1;
                state.setup.askToGoOut = 2;
                state.setup.black3s = 2;
            },
        },
        setSelectedPlayers: {
            reducer(state, action) {
                state.setup.players = action.payload.players.map((p) => ({
                    id: p.id,
                    userAccount: p,
                    name: p.name,
                    team: typeof p.team === 'number' ? p.team : null,
                    seat: typeof p.seat === 'number' ? p.seat : null
                }));
                state.error = null;
                state.setup.cardsToDeal = state.numberOfPlayers > 3 ? 11 : 15;
                state.setup.numberOfCanastas = 1;
            },
            prepare(players) {
                return {payload: {players }};
            }
        },
        shiftPlayersRight: {
            reducer(state, action) {
                const teamCount = 2;
                const seatsPerTeam = state.setup.numberOfPlayers / teamCount;

                state.setup.players = state.setup.players.map(oldPlayer => {
                    const player = { ...oldPlayer };
                    player.seat += 1;

                    if (player.seat >= seatsPerTeam) {
                        player.seat = 0;
                        player.team += 1;
                    }

                    if (player.team > teamCount) {
                        player.team = 1;
                        player.seat = 0;
                    }

                    return player;
                });
            },
        },
        setPositionOpen: {
            reducer(state, action) {
                state.setup.players = state.setup.players.filter(p => p.team !== action.payload.team || p.seat !== action.payload.seat);
                state.error = null;
            },
            prepare({team, seat}) {
                return {payload: {team, seat}};
            }
        },
        setNumberOfPlayers: {
            reducer(state, action) {
                state.setup.numberOfPlayers = action.payload.numberOfPlayers;
                state.error = null;
            },
            prepare(numberOfPlayers) {
                return {payload: {numberOfPlayers}};
            }
        },
        setCardsToDeal: {
            reducer(state, action) {
                state.setup.cardsToDeal = action.payload.cardsToDeal;
                state.error = null;
            },
            prepare(cardsToDeal) {
                return {payload: {cardsToDeal}};
            }
        },
        setTotalDecks: {
            reducer(state, action) {
                state.setup.totalDecks = action.payload.totalDecks;
                state.error = null;
            },
            prepare(totalDecks) {
                return {payload: {totalDecks}};
            }
        },
        setTotalPickupCards: {
            reducer(state, action) {
                state.setup.totalPickupCards = action.payload.totalPickupCards;
                state.error = null;
            },
            prepare(totalPickupCards) {
                return {payload: {totalPickupCards}};
            }
        },
        setRed3s: {
            reducer(state, action) {
                state.setup.red3s = action.payload.red3s;
                state.error = null;
            },
            prepare(red3s) {
                return {payload: {red3s}};
            }
        },
        setMaxWildCards: {
            reducer(state, action) {
                state.setup.maxWildCards = action.payload.maxWildCards;
                state.error = null;
            },
            prepare(maxWildCards) {
                return {payload: {maxWildCards}};
            }
        },
        setAddToCompletedCanastas: {
            reducer(state, action) {
                state.setup.addToCompletedCanastas = action.payload.addToCompletedCanastas;
                state.error = null;
            },
            prepare(addToCompletedCanastas) {
                return {payload: {addToCompletedCanastas}};
            }
        },
        setNumberOfCanastas: {
            reducer(state, action) {
                state.setup.numberOfCanastas = action.payload.numberOfCanastas;
                state.error = null;
            },
            prepare(numberOfCanastas) {
                return {payload: {numberOfCanastas}};
            }
        },
        setSwipeCards: {
            reducer(state, action) {
                state.setup.swipeCards = action.payload.swipeCards;
                state.error = null;
            },
            prepare(swipeCards) {
                return {payload: {swipeCards}};
            }
        },
        setAskToGoOut: {
            reducer(state, action) {
                state.setup.askToGoOut = action.payload.askToGoOut;
                state.error = null;
            },
            prepare(askToGoOut) {
                return {payload: {askToGoOut}};
            }
        },
        setBlack3s: {
            reducer(state, action) {
                state.setup.black3s = action.payload.black3s;
                state.error = null;
            },
            prepare(black3s) {
                return {payload: {black3s}};
            }
        },
    }
})

export const {
    setGameSetup,
    cancel,
    goToNextStep,
    goToPreviousStep,
    setSelectedPlayers,
    setCardsToDeal,
    setTotalDecks,
    setNumberOfPlayers,
    setTotalPickupCards,
    shiftPlayersRight,
    setRed3s,
    setMaxWildCards,
    setAddToCompletedCanastas,
    setNumberOfCanastas,
    setSwipeCards,
    setAskToGoOut,
    setBlack3s,
    setPositionBeingSelected,
    setPositionOpen,
} = gameSetupSlice.actions;

export default gameSetupSlice.reducer;

export const continueToNextStep = () => async (dispatch, getState) => {
    dispatch(goToNextStep());
    await UserSession.setNewGameSetup(getState().viewer.gameSetup);
};

export const backToPreviousStep = () => async (dispatch, getState) => {
    dispatch(goToPreviousStep());

    const state = getState();
    await UserSession.setNewGameSetup(state.viewer.gameSetup);
};

export const startSelectingPlayerForPosition = (positionBeingSelected) => async (dispatch, getState) => {
    dispatch(setPositionBeingSelected(positionBeingSelected));
    const state = getState();
    await UserSession.setNewGameSetup(state.viewer.gameSetup);
};

export const clearPlayerFromPosition = (team, seat) => async (dispatch, getState) => {
    dispatch(setPositionOpen(team, seat));
    const state = getState();
    await UserSession.setNewGameSetup(state.viewer.gameSetup);
};

export const stopSelectingPlayerForPosition = () => async (dispatch, getState) => {
    dispatch(setPositionBeingSelected(null));
    const state = getState();
    await UserSession.setNewGameSetup(state.viewer.gameSetup);
};

export const getGameSetupFromStorage = () => async (dispatch, getState) => {
    const gameSetup = await UserSession.getNewGameSetup();
    dispatch(setGameSetup(gameSetup));
};

export const shufflePlayers = () => async (dispatch, getState) => {
    dispatch(shiftPlayersRight());
    const state = getState();
    await UserSession.setNewGameSetup(state.viewer.gameSetup);
};