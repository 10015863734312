import React, {useCallback, useContext, useRef, useState} from "react";
import {connect} from "react-redux";
import {SafeAreaView} from "react-native-safe-area-context";
import StyleContext from "../../StyleContext";
import {LoadingContext} from "../../context/LoadingContext";
import {useFocusEffect} from "@react-navigation/core";
import {ScrollView, TouchableOpacity, View} from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import Colors from "../../styles/colors";
import IconButton from "../../sharedComponents/iconButton/IconButton";
import ChevronIcon from "../../icons/ChevronIcon";
import GameSetupPlayers from "./GameSetupPlayers";
import Spacing from "../../styles/spacing";
import {
    backToPreviousStep, cancel,
    continueToNextStep, setAddToCompletedCanastas, setAskToGoOut, setBlack3s,
    setCardsToDeal, setMaxWildCards, setNumberOfCanastas, setRed3s, setSwipeCards, setTotalDecks, setTotalPickupCards,
    setNumberOfPlayers, shufflePlayers
} from "../../redux/viewer/gameSetupSlice";
import GameSetupTeams from "./GameSetupTeams";
import GameSetupStep from "./GameSetupStep";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import {useNavigation} from "@react-navigation/native";
import Api from "../../services/Api";
import Analytics, {EVENTS} from "../../services/Analytics";

const GameSetupScreen = ({dispatch, userAccount, accessToken, gameSetup}) => {

    const styleContext = useContext(StyleContext);
    const loadingContext = useContext(LoadingContext);
    const scrollViewRef = useRef(null);
    const navigation = useNavigation();
    const [errorMessage, setErrorMessage] = useState('');
    const [createGameLoading, setCreateGameLoading] = useState(false);
    const [invitedFriends, setInvitedFriends] = useState([]);

    const progress = gameSetup.currentStep / SetupSteps.Count * 100;
    const isReviewStep = gameSetup.currentStep === SetupSteps.End;
    const isNormalContainer = gameSetup.currentStep < SetupSteps.StartOfSpecialContainer;

    useFocusEffect(useCallback(() => {
        loadingContext.setScreenLoading(false)
        return () => {
            setInvitedFriends([]);
        }
    }, []));

    useFocusEffect(useCallback(() => {
        zE('webWidget', 'hide');
    }, [navigation]));

    const handleOnBackPress = () => {
        if (gameSetup.currentStep === SetupSteps.First) {
            handleOnCancelPress();
        } else {
            dispatch(backToPreviousStep())
        }
    };

    const handleOnCancelPress = () => {
        dispatch(cancel())
        navigation.navigate('Home');
    };

    const handleCreateGameButton = async () => {
        setErrorMessage('');
        setCreateGameLoading(true);
        try {
            const game = await Api.createGame(accessToken, gameSetup.setup);
            setCreateGameLoading(false);
            dispatch(cancel());
            await Analytics.logEvent(EVENTS.GAME_CREATE, {
                id: game.id,
                alphanumeric_id: game.alphanumericId,
                total_players: gameSetup.setup.players.length
            });
            let params = {id: game.alphanumericId};
            const gameMessages = JSON.parse(game.gameMessages);
            const partialStates = JSON.parse(game.partialStates);

            if (partialStates.length > 0 || gameMessages.length > 0) {
                params.e = {ps: partialStates, gm: gameMessages};
            }

            navigation.navigate('GameTable', params);
        } catch(error) {
            console.error('error', error);
            setErrorMessage(error.message);
            setCreateGameLoading(false);
        }
    };

    const nextStep = () => {
        dispatch(continueToNextStep());
        if (scrollViewRef.current) {
            if (isReviewStep) {
                setTimeout(() => {
                    scrollViewRef.current.scrollTo({x: 0, y: 0, animated: true})
                }, 200);
            } else {
                setTimeout(() => {
                    scrollViewRef.current.scrollToEnd({animated: true})
                }, 200);
            }
        }
    }

    const handleOnOptionNumberOfPlayers = (option) => {
        dispatch(setNumberOfPlayers(option));
        nextStep();
    }

    const handleOnShufflePlayers = () => {
        dispatch(shufflePlayers());
    }

    const handleOnOptionSelectedCards = (option) => {
        dispatch(setCardsToDeal(option));
        nextStep();
    }

    const handleOnOptionSelectedDecks = (option) => {
        dispatch(setTotalDecks(option));
        nextStep();
    }

    const handleOnOptionSelectedPickupCards = (option) => {
        dispatch(setTotalPickupCards(option));
        nextStep();
    }

    const handleOnOptionSelectedRed3s = (option) => {
        dispatch(setRed3s(option));
        nextStep();
    }

    const handleOnOptionSelectedMaxWildCards = (option) => {
        dispatch(setMaxWildCards(option));
        nextStep();
    }

    const handleOnOptionSelectedAddToCompletedCanastas = (option) => {
        dispatch(setAddToCompletedCanastas(option));
        nextStep();
    }

    const handleOnOptionSelectedNumberOfCanastas = (option) => {
        dispatch(setNumberOfCanastas(option));
        nextStep();
    }

    const handleOnOptionSelectedSwipeCards = (option) => {
        dispatch(setSwipeCards(option));
        nextStep();
    }

    const handleOnOptionSelectedAskToGoOut = (option) => {
        dispatch(setAskToGoOut(option));
        nextStep();
    }

    const handleOnOptionSelectedBlack3s = (option) => {
        dispatch(setBlack3s(option));
        nextStep();
    }

    const handleSetInvitedFriends = (friends) => {
        setInvitedFriends(friends);
    };

    return (
        <SafeAreaView
            style={[styleContext.safeArea, styleContext.container, {
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'stretch',
                backgroundColor: Colors.background3,
                paddingTop: 72,
                overflow: 'hidden',
                position: 'absolute',
                maxHeight: '100%',
                height: '100%',
                width: '100%'
            }]}>

            <View style={{...ownStyles.header}}>
                <View style={{...ownStyles.headerProgressBar, width: progress + '%'}}/>
                <View style={{position: 'absolute', top: 15, left: 15, zIndex: 1, transform: [{rotate: '180deg'}]}}>
                    <IconButton icon={<ChevronIcon size={20} color={Colors.onBackground}/>}
                                onPress={handleOnBackPress} disabled={createGameLoading}/>
                </View>
                <TouchableOpacity style={{position: 'absolute', top: 0, bottom: 0, justifyContent: 'center', right: 15, zIndex: 1}} onPress={handleOnCancelPress} disabled={createGameLoading}>
                    <StandardText>Cancel</StandardText>
                </TouchableOpacity>
                <View>
                    <StandardText style={{fontSize: 20, weight: 500, color: Colors.onBackground4}}>
                        {isReviewStep ? `Review Game Setup` : `Set up your game`}
                    </StandardText>
                </View>
            </View>

            {!isNormalContainer && (
                <View style={{height: '100%', paddingBottom: isReviewStep ? 98 : (!isNormalContainer ? 310 : 0)}}>
                    <ScrollView ref={scrollViewRef} style={{height: '100%'}}>
                        {gameSetup.currentStep >= SetupSteps.SetupNumberOfPlayers && gameSetup.setup.players.length > 0 && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText style={{...ownStyles.gameSetupLabel}}># of players</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}></StandardText>

                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{gameSetup.setup.numberOfPlayers}</StandardText>
                            </View>
                        )}
                        {gameSetup.currentStep > SetupSteps.SetupCardsDealt && gameSetup.setup.cardsToDeal !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText style={{...ownStyles.gameSetupLabel}}>Cards dealt</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>Select how many cards you want dealt in each player’s hand and in their foot. We recommend 11 or 13 cards in a 4 player game and 15 cards in a 2 player game.</StandardText>

                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{gameSetup.setup.cardsToDeal}</StandardText>
                            </View>
                        )}
                        {gameSetup.currentStep > SetupSteps.SetupNumberOfCanastas && gameSetup.setup.numberOfCanastas !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText
                                        style={{...ownStyles.gameSetupLabel}}>{`Number of canastas to go out`}</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>A "natural" canasta means a meld of 7+ cards with no wild cards and an "unnatural" canasta means a meld of 7+ cards that includes one or more wild cards.</StandardText>
                                </View>
                                <StandardText style={{...ownStyles.gameSetupValue}}>
                                    {gameSetup.setup.numberOfCanastas === 1
                                        ? `1 Natural, 1 Unnatural`
                                        : gameSetup.setup.numberOfPlayers === 2
                                            ? '3 Natural, 4 Unnatural'
                                            : [4, 6].includes(gameSetup.setup.numberOfPlayers) && gameSetup.setup.swipeCards === 1
                                                ? '3 Natural, 4 Unnatural'
                                                : '4 Natural, 5 Unnatural'}
                                </StandardText>
                            </View>
                        )}

                        {gameSetup.currentStep > SetupSteps.SetupSwipeCards && gameSetup.setup.numberOfCanastas !== null && (
                            <View style={{...ownStyles.gameSetupRow}}>
                                <View style={{...ownStyles.gameSetupLabelContainer}}>
                                    <StandardText
                                        style={{...ownStyles.gameSetupLabel}}>{`Play with swipe cards`}</StandardText>
                                    <StandardText style={{...ownStyles.gameSetupLabelDescription}}>Opponent team's cards of the same rank are swiped when your team makes a canasta, adding to your point total. Cards in existing canastas cannot be swiped.</StandardText>
                                </View>
                                <StandardText
                                    style={{...ownStyles.gameSetupValue}}>{gameSetup.setup.swipeCards === 1 ? `Yes` : `No`}</StandardText>
                            </View>
                        )}
                    </ScrollView>
                </View>
            )}

            <View
                style={{...(isNormalContainer ? ownStyles.contentContainer : (isReviewStep ? ownStyles.contentContainerReview : ownStyles.contentContainerModal))}}>

                
                {gameSetup.currentStep === SetupSteps.SetupNumberOfPlayers && (
                    <GameSetupStep
                        stepLabel={'How many players?'}
                        stepDescription={'Select how many players will be involved in this game.'}
                        stepOptions={[{id: 2, label: '2'}, {id: 4, label: '4'}]}
                        selectedOptionId={gameSetup.setup.numberOfPlayers}
                        onOptionSelected={handleOnOptionNumberOfPlayers}
                        gameSetup={gameSetup}
                        userAccount={userAccount}
                    />
                )}

                {gameSetup.currentStep === SetupSteps.SetupPlayers && (
                    <GameSetupPlayers onContinuePress={() => {
                        dispatch(continueToNextStep())
                    }} invitedFriends={invitedFriends} setInvitedFriends={handleSetInvitedFriends}/>
                )}

                {gameSetup.currentStep === SetupSteps.SetupTeams && (
                    <GameSetupTeams onContinuePress={() => {
                        dispatch(continueToNextStep())
                    }} onShufflePlayers={handleOnShufflePlayers} gameSetup={gameSetup} userAccount={userAccount}/>
                )}

                {gameSetup.currentStep === SetupSteps.SetupCardsDealt && (
                    <GameSetupStep
                        stepLabel={'How many cards do you want to be dealt?'}
                        stepDescription={'Select how many cards you want dealt in each player’s hand and in their foot. We recommend 11 or 13 cards in a 4 player game and 15 cards in a 2 player game.'}
                        stepOptions={[{id: 11, label: '11'}, {id: 13, label: '13'}, {id: 15, label: '15'}]}
                        selectedOptionId={gameSetup.setup.cardsToDeal}
                        onOptionSelected={handleOnOptionSelectedCards}
                        gameSetup={gameSetup}
                        userAccount={userAccount}
                    />
                )}

                {gameSetup.currentStep === SetupSteps.SetupNumberOfCanastas && (
                    <GameSetupStep
                        stepLabel={'Number of canastas to go out'}
                        stepDescription={'A "natural" canasta means a meld of 7+ cards with no wild cards and an "unnatural" canasta means a meld of 7+ cards that includes one or more wild cards.'}
                        stepOptions={[{id: 1, label: `1 Natural, 1 Unnatural`, extra: `Best for shorter, more\nlively games`}, {id: 2, label: ([2, 3].includes(gameSetup.setup.numberOfPlayers) ? '3 Natural, 4 Unnatural' : '4 Natural, 5 Unnatural'), extra: `Best for longer, more\nstrategic games` }]}
                        selectedOptionId={gameSetup.setup.numberOfCanastas}
                        onOptionSelected={handleOnOptionSelectedNumberOfCanastas}
                        gameSetup={gameSetup}
                        userAccount={userAccount}
                        vertical={true}
                    />
                )}

                {gameSetup.currentStep === SetupSteps.SetupSwipeCards && (
                    <GameSetupStep
                        stepLabel={'[BETA] Play with swipe cards?'}
                        stepDescription={'Opponent team\'s cards of the same rank are swiped when your team makes a canasta, adding to your point total. Your cards can\'t be swiped if you have already made a canasta in that rank.'}
                        stepOptions={[{id: 1, label: 'Yes'}, {id: 2, label: 'No'}]}
                        selectedOptionId={gameSetup.setup.swipeCards}
                        onOptionSelected={handleOnOptionSelectedSwipeCards}
                        gameSetup={gameSetup}
                        userAccount={userAccount}
                    />
                )}

                {isReviewStep && (
                    <View style={{width: '100%', maxWidth: 420}}>
                        <LoadingButton
                            label={'Start game'}
                            backgroundColor={Colors.primary6}
                            padding={Spacing.large}
                            loading={createGameLoading}
                            onPress={handleCreateGameButton} />
                    </View>
                )}

            </View>

        </SafeAreaView>
    );
};

const SetupSteps = (() => {
    const steps = {
        SetupNumberOfPlayers:  1,
        SetupPlayers: 2,
        SetupTeams: 3,
        SetupCardsDealt: 4,
        SetupNumberOfCanastas: 5,
        SetupSwipeCards: 6,

        End: 7,
        First: 1,
        Count: 0,
        StartOfSpecialContainer: 0,
    };

    steps.Count = steps.End;
    steps.StartOfSpecialContainer = steps.SetupCardsDealt;

    return steps;
})();

const mapStateToProps = (state) => {
    return {
        userAccount: state.auth.login.userAccount,
        accessToken: state.auth.login.accessToken,
        friends: state.viewer.friends.data,
        friendsCursor: state.viewer.friends.cursor,
        friendsLoading: state.viewer.friends.loading,
        gameSetup: state.viewer.gameSetup,
    }
};

export default connect(mapStateToProps)(GameSetupScreen);

const ownStyles = {
    header: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        height: 72,
        backgroundColor: Colors.background,
        zIndex: 1
    },
    headerProgressBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        backgroundColor: Colors.primary5
    },
    contentContainer: {
        flex: 1,
        width: '100%',
        padding: Spacing.base,
        paddingBottom: 50,
        backgroundColor: 'transparent',
        alignItems: 'center',
        overflowY: 'auto'
    },
    contentContainerModal: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        borderWidth: 1,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderColor: 'rgba(192, 192, 192, 1)',
        flex: 1,
        width: '100%',
        height: 300,
        backgroundColor: Colors.background,
        padding: Spacing.base,
        alignItems: 'center'
    },
    contentContainerReview: {
        position: 'fixed',
        bottom: 0,
        borderTopWidth: 1,
        borderTopColor: 'rgba(192, 192, 192, 1)',
        flex: 1,
        width: '100%',
        height: 88,
        backgroundColor: Colors.background,
        padding: Spacing.base,
        alignItems: 'center'
    },
    gameSetupRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: Spacing.large,
        paddingStart: Spacing.base,
        borderBottomWidth: 1,
        borderBottomColor: Colors.onBackgroundBorder2
    },
    gameSetupLabelContainer: {
        flex: 1
    },
    gameSetupLabel: {
        color: Colors.onBackground3
    },
    gameSetupLabelDescription: {
        fontSize: 14,
        color: Colors.onBackground3,
        opacity: 0.7,
        marginTop: Spacing.small,
    },
    gameSetupValue: {
        color: Colors.primary4,
        paddingStart: Spacing.base,
        textAlign: 'right'
    }
};
