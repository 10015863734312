
import React from 'react';
import { Svg, Path } from 'react-native-svg';

const FlagIcon = ({ size, color }) => {
    return (
        // <Svg width={size} height={size} viewBox="0 0 16 14" fill="none">
        //     <Path d="M15.9306 2.98902C13.8597 5.77784 12.3731 7.36189 11.4707 7.74121C10.5683 8.12053 9.81634 8.11786 9.21475 7.7332C8.61316 7.34853 7.94216 6.99326 7.20174 6.66736C6.46131 6.34146 5.21187 6.85701 3.45336 8.21403L5.04989 14H3.26247L0 2.17161L1.64859 1.62667C3.61534 0.301716 4.9689 -0.213834 5.70933 0.080007C6.44975 0.373848 7.12653 0.868028 7.7397 1.56256C8.35286 2.2571 9.17136 2.84744 10.1952 3.33362C11.2191 3.81979 13.0036 3.59273 15.5488 2.65244C15.7108 2.59902 15.838 2.6097 15.9306 2.6845C16.0231 2.75929 16.0231 2.8608 15.9306 2.98902Z" fill={color} />
        // </Svg>

        <Svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke={color || "currentColor"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><Path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></Svg>
    );
};

export default FlagIcon;