import React, { useCallback, useContext, useRef, useState } from "react";
import { Image, TouchableOpacity, View } from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import StyleContext from "../../StyleContext";
import { SafeAreaView } from "react-native-safe-area-context";
import { connect } from "react-redux";
import { fetchViewerGames } from "../../redux/viewer/actions/games";
import { useFocusEffect } from "@react-navigation/core";
import Colors from "../../styles/colors";
import InfoIcon from "../../icons/InfoIcon";
import blurredSuitsBg from './../../../assets/blurred_suits_bg.png';
import { LoadingContext } from "../../context/LoadingContext";
import GamesList from "../../game/components/GamesList";
import GameConfigSummary from "../../game/components/GameConfigSummary";
import { useNavigation } from "@react-navigation/native";
import TutorialIcon from "../../icons/TutorialIcon";
import Analytics, { EVENTS } from "../../services/Analytics";
import Api from "../../services/Api";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import TabGroup from "../../sharedComponents/tabGroup/TabGroup";
import OptionPicker from "../../sharedComponents/optionPicker/OptionPicker";
import ConfirmationDialog from "../../sharedComponents/confirmationDialog/ConfirmationDialog";
import GameHelper from "../../game/GameHelper";

const ContinueGameScreen = ({ dispatch, accessToken, userAccount, games, gamesLoading, cursor, subscription }) => {
    const navigation = useNavigation();
    const [initialFetchTriggered, setInitialFetchTriggered] = useState(false);
    const [selectedTab, setSelectedTab] = useState(2);
    const [selectedGameFilterOption, setSelectedGameFilterOption] = useState(1);
    let previousCursor = useRef(null);
    let changedCursor = useRef(false);
    const loadingContext = useContext(LoadingContext);
    const styleContext = useContext(StyleContext);
    const [showRules, setShowRules] = useState(false);
    const [generalAppInfo, setGeneralAppInfo] = useState(null);
    const [showConfirmArchiveModal, setShowConfirmArchiveModal] = useState(false);
    const [gameToToggleArchive, setGameToToggleArchive] = useState(null);
    const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
    const [gameToToggleRemove, setGameToToggleRemove] = useState(null);
    const [emptyGamesMessage, setEmptyGamesMessage] = useState(`No games yet.\nPress + to start a new game.`);

    useFocusEffect(useCallback(() => {
        return () => {
            setInitialFetchTriggered(false);
            setSelectedTab(2);
            setSelectedGameFilterOption(1);
        }
    }, []));

    useFocusEffect(useCallback(() => {
        if (userAccount) {
            setInitialFetchTriggered(true);
            dispatch(fetchViewerGames(selectedGameFilterOption, null))
        }
    }, [userAccount]));

    useFocusEffect(useCallback(() => {
        if (userAccount) {
            dispatch(fetchViewerGames(selectedGameFilterOption, null))
        }
    }, [selectedGameFilterOption]));

    useFocusEffect(useCallback(() => {
        if (initialFetchTriggered && !gamesLoading) {
            loadingContext.setScreenLoading(false);
        }
    }, [initialFetchTriggered, gamesLoading]));

    useFocusEffect(useCallback(() => {
        changedCursor.current = previousCursor.current !== cursor;
        previousCursor.current = cursor;
    }, [cursor]));

    useFocusEffect(useCallback(() => {
        if (accessToken) {
            getInfo(accessToken);
        }
    }, [accessToken]));

    useFocusEffect(useCallback(() => {
        if (selectedGameFilterOption == 1) {
            setEmptyGamesMessage(`Press + to start a new game.\n\nCan't find a game you already started?\nCheck the "Archived" tab.`);
        } else if (selectedGameFilterOption == 2) {
            setEmptyGamesMessage(`No archived games yet. An archived game can still be played, it just will not show in your Active area.\n\nTo archive a game, go to the "Active" tab and tap/click on the left side of a game until you see the "Archive" button.`);
        } else if (selectedGameFilterOption == 3) {
            setEmptyGamesMessage(`Press + to start a new game or continue an "Active" or "Archived" game you have already started.`);
        }
    }, [selectedGameFilterOption]));

    const getInfo = async (accessToken) => {
        const generalAppInfoData = await Api.generalAppInfo(accessToken);
        setGeneralAppInfo(generalAppInfoData);
    };

    const handleGameItemPress = (game) => {
        navigation.navigate('GameTable', {
            id: game.alphanumericId
        });
    };

    const handleEndReached = () => {
        if (changedCursor.current && cursor) {
            changedCursor.current = false;
            dispatch(fetchViewerGames(selectedGameFilterOption, cursor));
        }
    };

    const handleOnRulesPress = () => {
        Analytics.logEvent(EVENTS.BUTTON_PRESS, { name: 'rules' });
        setShowRules(true)
    };

    const handleOnTutorialPress = () => {
        Analytics.logEvent(EVENTS.BUTTON_PRESS, { name: 'tutorial' });
        navigation.navigate('Tutorial', { reset: 1 })
    };

    const handleGameItemArchiveTogglePress = async (game) => {
        setGameToToggleArchive(game);
        setShowConfirmArchiveModal(true)
    }

    const handleConfirmArchiveTogglePress = async() => {
        setShowConfirmArchiveModal(false);
        const archived = await Api.toggleArchiveGame(accessToken, gameToToggleArchive.alphanumericId);
        setGameToToggleArchive(null);
        dispatch(fetchViewerGames(selectedGameFilterOption, null));
    }

    const handleGameItemRemoveTogglePress = async (game) => {
        setGameToToggleRemove(game);
        setShowConfirmRemoveModal(true)
    }

    const handleConfirmRemoveTogglePress = async() => {
        setShowConfirmRemoveModal(false);
        const removed = await Api.toggleRemoveGame(accessToken, gameToToggleRemove.alphanumericId);
        setGameToToggleRemove(null);
        dispatch(fetchViewerGames(selectedGameFilterOption, null));
    }

    const handleGameFilterOptionChanged = (id) => { 
        changedCursor.current = false;
        previousCursor.current = null;
        setSelectedGameFilterOption(id);
    }

    const handleTabSelected = (id) => {
        setSelectedTab(id);
        if (id === 1) {
            setSelectedGameFilterOption(4);
        } else if (selectedGameFilterOption === 4) {
            setSelectedGameFilterOption(1);
        }
    };

    return (
        <SafeAreaView
            style={[styleContext.safeArea, styleContext.container, {
                flexDirection: 'column',
                justifyContent: 'start',
                alignItems: 'center'
            }]}>

            <ConfirmationDialog title={'Confirmation'}
                content={gameToToggleArchive && GameHelper.getViewerPlayer(gameToToggleArchive)?.archived ? 'This will move the game to your Active area. Do you want to continue?' : 'This will move the game to your Archive area. Do you want to continue? An archived game can still be played, it just will not show in your Active area.'}
                onCancelPress={() => {
                    setShowConfirmArchiveModal(false);
                    setGameToToggleArchive(null);
                }}
                onConfirmPress={handleConfirmArchiveTogglePress}
                visible={showConfirmArchiveModal} />

            <ConfirmationDialog title={'Confirmation'}
                content={gameToToggleRemove ? 'This will delete the game from your view and you will not be able to play it in the future. Do you want to continue?' : ''}
                onCancelPress={() => {
                    setShowConfirmRemoveModal(false);
                    setGameToToggleRemove(null);
                }}
                onConfirmPress={handleConfirmRemoveTogglePress}
                visible={showConfirmRemoveModal} />

            {showRules && (
                <View style={{ ...ownStyles.rulesContainer }}>
                    <GameConfigSummary onlyGeneralRules={true} accessToken={accessToken} onBackButtonPress={() => {
                        setShowRules(false)
                    }} />
                </View>
            )}

            <View style={{ ...ownStyles.blurredSuitsBgContainer }}>
                <Image source={{ uri: blurredSuitsBg }} style={{ width: 300, height: 306 }} />
            </View>
            <View style={{ ...ownStyles.topBar, }}>
                <View>
                    <StandardText style={{ ...ownStyles.screenTitle }}>Home</StandardText>
                </View>
                <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity style={{ ...ownStyles.rulesButton }} onPress={handleOnRulesPress}>
                        <InfoIcon size={24} color={'rgba(70, 74, 83, 1)'} />
                        <StandardText style={{ ...ownStyles.rulesButtonText }}>Rules</StandardText>
                    </TouchableOpacity>
                    <TouchableOpacity style={{ ...ownStyles.rulesButton, marginStart: 10 }}
                        onPress={handleOnTutorialPress}>
                        <TutorialIcon size={21} color={'rgba(70, 74, 83, 1)'} />
                        <StandardText style={{ ...ownStyles.rulesButtonText }}>Tutorial</StandardText>
                    </TouchableOpacity>
                </View>
            </View>
            <View style={{ width: '100%', flexGrow: 1 }}>
                <View style={{ ...ownStyles.startGameButtonsContainer }}>
                    <View style={{ width: 250 }}>
                        <LoadingButton label="Play Daily Tournament" showChevron={false} backgroundColor={Colors.tournamentColor} maxWidth={'100%'} onPress={() => navigation.navigate('DailyTournament')} />
                        <LoadingButton label="New Game" showChevron={false} maxWidth={'100%'} onPress={() => navigation.navigate('NewGame')} showPlus={true} />
                    </View>
                </View>

                <View style={{ ...ownStyles.gameListContainer }}>
                    <View style={{ width: '100%', maxWidth: 800, padding: 12 }}>
                        <TabGroup selectedTabId={selectedTab} tabs={[{id: 1, label: 'Open Games'}, { id: 2, label: 'Your Games' }]} onTabSelected={(id) => { handleTabSelected(id) }} />
                    </View>
                </View>

                {selectedTab === 2 && (
                    <View style={{ ...ownStyles.gameListContainer, flex: 1 }}>

                        <View style={{ width: '100%', maxWidth: 800, paddingBottom: 12, marginTop: 10, marginBottom: 10 }}>
                            <View style={{ width: '100%', maxWidth: 300, alignItems: 'stretch', alignSelf: 'center' }}>
                                <OptionPicker
                                    color={Colors.primary4}
                                    options={[{ id: 1, label: 'ACTIVE' }, { id: 2, label: 'ARCHIVED' }, { id: 3, label: 'COMPLETED' }]}
                                    selectedId={selectedGameFilterOption}
                                    onOptionSelected={handleGameFilterOptionChanged} />
                            </View>
                        </View>

                        <GamesList games={games} loading={!initialFetchTriggered || gamesLoading}
                        emptyListMessage={emptyGamesMessage}
                            onGameItemPress={handleGameItemPress} onEndReached={handleEndReached} onGameItemArchiveToogglePress={handleGameItemArchiveTogglePress} onGameItemRemoveToogglePress={handleGameItemRemoveTogglePress} />
                    </View>
                )}

                {selectedTab === 1 && (
                    <View style={{ ...ownStyles.gameListContainer, flex: 1 }}>

                        <View style={{ width: '85%', maxWidth: 800, paddingBottom: 12, marginTop: 10, marginBottom: 10 }}>
                            <StandardText>Open Games are available for any player to join. Tap on a game you want to join, or create a new open game with the “New Game +” button.</StandardText>
                        </View>

                        <GamesList games={games} loading={!initialFetchTriggered || gamesLoading}
                        emptyListMessage={emptyGamesMessage}
                            onGameItemPress={handleGameItemPress} onEndReached={handleEndReached} onGameItemArchiveToogglePress={handleGameItemArchiveTogglePress} onGameItemRemoveToogglePress={handleGameItemRemoveTogglePress} />
                    </View>
                )}

            </View>
        </SafeAreaView>
    );
};

const ownStyles = {
    rulesContainer: {
        position: 'fixed',
        left: 0,
        right: 0,
        height: '100%',
        zIndex: 2,
        flex: 1,
        alignItems: 'stretch',
        backgroundColor: Colors.background
    },
    topBar: {
        width: '100%',
        padding: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    screenTitle: {
        fontSize: 20,
        weight: 500,
        color: Colors.onBackground4
    },
    rulesButton: {
        flexDirection: 'row',
        borderWidth: 1,
        borderRadius: 5,
        borderColor: 'rgba(242, 242, 242, 1)',
        padding: 8,
        alignItems: 'center',
    },
    rulesButtonText: {
        color: 'rgba(77, 77, 77, 1)',
        fontSize: 14,
        marginStart: 10
    },
    blurredSuitsBgContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center'
    },
    startGameButtonsContainer: {
        width: '100%',
        alignItems: 'center',
        marginBottom: 0
    },
    gameListContainer: {
        width: '100%',
        alignItems: 'center'
    },
    newGameButton: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        width: 63,
        height: 63,
        backgroundColor: Colors.primary4,
        borderRadius: 64,
        justifyContent: 'center',
        alignItems: 'center'
    }
};

const mapStateToProps = (state) => {
    return {
        accessToken: state.auth.login.accessToken,
        userAccount: state.auth.login.userAccount,
        games: state.viewer.games.data,
        cursor: state.viewer.games.cursor,
        gamesLoading: state.viewer.games.loading,
        gamesError: state.viewer.games.error,
        subscription: state.auth.profile.subscription,
    }
};

export default connect(mapStateToProps)(ContinueGameScreen);
