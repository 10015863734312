import React, {useCallback, useState} from "react";
import {View} from "react-native";
import StandardText from "../../sharedComponents/standardText/StandardText";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import {useFocusEffect} from "@react-navigation/core";
import Spacing from "../../styles/spacing";
import Colors from "../../styles/colors";

const GameSetupTeams = ({onContinuePress, onShufflePlayers, gameSetup, userAccount}) => {

    const [shuffleIteration, setShuffleIteration] = useState(0);
    const combinations = Math.floor(Math.log2(gameSetup.setup.numberOfPlayers)) + 1;
    const teamCount = 2;
    const seatsPerTeam = gameSetup.setup.numberOfPlayers / teamCount;

    useFocusEffect(useCallback(() => {
    }, [gameSetup.setup.players]));

    const handleShufflePlayersPress = () => {
        if (shuffleIteration < combinations) {
            setShuffleIteration(shuffleIteration + 1);
        } else {
            setShuffleIteration(0);
        }
        onShufflePlayers();
    };

    const teamViews = [];
    for (let team = 1; team <= teamCount; team++) {
        const seatViews = [];
        for (let seat = 0; seat < seatsPerTeam; seat++) {
            const player = gameSetup.setup.players.find(p => p.team === team && p.seat === seat);
            const isSelf = player && player.id === userAccount?.id;
            const name = player ? ( isSelf ? 'You' : player.user?.firstName || player.userAccount?.user?.firstName || player.name) : 'Open';
            const username = player ? "(" + (player.userAccount?.userAccount?.username || player.userAccount?.username || player.username || 'Computer') + ")" : '';

            seatViews.push(
                <View key={seat} style={{
                    ...ownStyles.item,
                    flexDirection: 'row', ...ownStyles.itemSelected,
                }}>
                    <View>
                        <StandardText style={{...(isSelf ? {color: Colors.primary4} : {})}}>
                            {name}
                        </StandardText>
                    </View>
                    <View style={{marginLeft: 5}}>
                        <StandardText
                            style={{color: Colors.onBackground3, fontSize: 14, opacity: 0.7}}>
                                {username}
                        </StandardText>
                    </View>
                </View>
            );
        }

        teamViews.push(
            <View key={team}>
                <View style={{...ownStyles.teamContainer}}>
                    {seatViews}
                </View>

                {team < teamCount - 1 && (
                    <View>
                        <StandardText style={{textAlign: 'center', weight: 500}}>vs</StandardText>
                    </View>
                )}
            </View>
        );
    }

    return (
        <View style={{
            flexDirection: 'column',
            width: '100%',
            justifyContent: 'stretch',
            alignItems: 'stretch',
            flexGrow: 1,
            maxWidth: 420,
            minWidth: 200,
        }}>
            <View style={{flexDirection: 'column', flexGrow: 1, marginTop: 10, paddingBottom: 10}}>
                <StandardText style={{fontSize: 24, weight: 500, lineHeight: 32, textAlign: 'center'}}>
                    {`Play game with the teams\nbelow or re-shuffle teams.`}
                </StandardText>

                <View style={{marginTop: 20}}>
                    <LoadingButton
                        label={`Re-Shuffle Teams (${shuffleIteration + 1} of ${combinations + 1})`}
                        onPress={handleShufflePlayersPress}
                        showChevron={false}/>
                </View>

                <View style={{...ownStyles.teamsContainer}}>
                    {teamViews}
                </View>

                <View style={{...ownStyles.continueButtonContainer}}>
                    <View style={{width: '100%', maxWidth: 420, minWidth: 200, paddingStart: Spacing.base, paddingEnd: Spacing.base}}>
                        <LoadingButton label={'Continue'}
                                       loading={false}
                                       disabled={false}
                                       onPress={onContinuePress}/>
                    </View>
                </View>
            </View>
        </View>
    );
};

const ownStyles = {
    teamsContainer: {
        marginTop: Spacing.base
    },
    teamContainer: {
        marginTop: Spacing.large,
        marginBottom: Spacing.large,
    },
    item: {
        alignItems: 'center',
        backgroundColor: Colors.background,
        borderWidth: 1,
        borderRadius: 8,
        borderColor: Colors.onBackgroundBorder2,
        marginTop: 3,
        marginBottom: 3,
        padding: 13,
        height: 48
    },
    itemSelected: {
        borderColor: Colors.primary4,
    },
    checkboxWrapper: {
        width: '100%',
    },
    teamTitle: {
        fontSize: 12,
        marginLeft: 10,
        marginBottom: 5,
        textTransform: 'uppercase',
        color: Colors.onBackground2
    },
    continueButtonContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        alignItems: 'center',
        backgroundColor: Colors.background,
    },
};

export default GameSetupTeams;
