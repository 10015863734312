import { useFocusEffect, useNavigation } from "@react-navigation/core";
import React, { useCallback, useContext, useRef, useState } from "react";
import {
  Image,
  View,
  ScrollView,
  TouchableOpacity,
  TextInput,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { connect } from "react-redux";
import Colors from "../../styles/colors";
import { LoadingContext } from "../../context/LoadingContext";
import Api from "../../services/Api";
import StyleContext from "../../StyleContext";
import StandardText from "../../sharedComponents/standardText/StandardText";
import blurredSuitsBg from "./../../../assets/blurred_suits_bg.png";
import TabGroup from "../../sharedComponents/tabGroup/TabGroup";
import { fetchViewerFriends } from "../../redux/viewer/actions/friends";
import LoadingButton from "../../sharedComponents/loadingButton/LoadingButton";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from "react-native-popup-menu";
import IconButton from "../../sharedComponents/iconButton/IconButton";
import ProfileIcon from "../../icons/ProfileIcon";

const MessagesScreen = ({
  dispatch,
  accessToken,
  userAccount,
  subscription,
  route,
  friends,
}) => {
  const navigation = useNavigation();
  const loadingContext = useContext(LoadingContext);
  const styleContext = useContext(StyleContext);

  const [generalAppInfo, setGeneralAppInfo] = useState(null);
  const containerRef = useRef();
  const [moreMessagesLoading, setMoreMessagesLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [selectedTab, setSelectedTab] = useState(1);
  const messageType = selectedTab === 1 ? "received" : "sent";
  const mounted = useRef(false);

  const [composeRecipient, setComposeRecipient] = useState("");
  const [composeMessageContent, setComposeMessageContent] = useState("");
  const [sendingMessage, setSendingMessage] = useState(false);
  const [composeErrorMessage, setComposeErrorMessage] = useState(null);
  const [composeSuccessMessage, setComposeSuccessMessage] = useState(null);

  const [availableFriends, setAvailableFriends] = useState([]);

  useFocusEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  const loadMessages = useCallback(
    (messages, before) => {
      setMoreMessagesLoading(true);
      console.log("waiting for messages");
      Api.userMessages(accessToken, before, 10, messageType).then(
        (newMessages) => {
          if (mounted.current) {
            setMoreMessagesLoading(false);
            newMessages = newMessages.map((message) => ({
              ...message,
              createdAt: new Date(message.createdAt),
            }));
            const workingMessages = [...messages, ...newMessages];
            workingMessages.sort(
              (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
            );
            setMessages(workingMessages);
          }
        }
      );
    },
    [accessToken, messageType]
  );

  useFocusEffect(
    useCallback(() => {
      if (userAccount) {
        dispatch(fetchViewerFriends(null));
      }
    }, [dispatch, userAccount])
  );

  useFocusEffect(
    useCallback(() => {
      setAvailableFriends(friends.map((edge) => ({ ...edge.node })));
    }, [friends])
  );

  useFocusEffect(
    useCallback(() => {
      if (generalAppInfo) {
        loadingContext.setScreenLoading(false);
      }
    }, [generalAppInfo])
  );

  useFocusEffect(
    useCallback(() => {
      if (accessToken) {
        getInfo(accessToken);
      }
    }, [accessToken])
  );

  const getInfo = async (accessToken) => {
    const generalAppInfoData = await Api.generalAppInfo(accessToken);
    setGeneralAppInfo(generalAppInfoData);
  };

  const formatDate = (date) => {
    return date.toLocaleString();
  };

  useFocusEffect(
    useCallback(() => {
      if (accessToken && selectedTab < 3) {
        loadMessages([], null);
      }
    }, [accessToken, selectedTab])
  );

  const handleSendMessagePress = async () => {
    setComposeSuccessMessage(null);
    if (composeMessageContent.length > 1024) {
      setComposeErrorMessage(
        "Message must be less than or equal to 1024 characters"
      );
      return;
    } else if (composeMessageContent.length === 0) {
      setComposeErrorMessage("Message cannot be empty");
      return;
    } else if (composeRecipient.length === 0) {
      setComposeErrorMessage("Recipient cannot be empty");
      return;
    } else {
      setComposeErrorMessage(null);
    }

    try {
      setSendingMessage(true);
      await Api.sendUserMessage(
        accessToken,
        composeRecipient,
        composeMessageContent
      ).then(() => {
        setSendingMessage(false);
        loadMessages([], null);
      });
      setComposeMessageContent("");
      setComposeRecipient("");
      setComposeSuccessMessage(
        "Message successfully sent to " + composeRecipient
      );
    } catch (e) {
      setComposeErrorMessage(
        "An error occurred while sending your message, please try again later"
      );
    } finally {
      setSendingMessage(false);
    }
  };

  let tabContents;
  if (selectedTab === 1 || selectedTab === 2) {
    tabContents = messages.map((message) => (
      <View style={{ ...ownStyles.message }} key={message.id}>
        <View style={{ flexDirection: "row", marginBottom: 8 }}>
          <View style={{ display: "flex", flexDirection: "row" }}>
            {message.isSender ? (
              <>
                <StandardText
                  style={{ ...ownStyles.messageSenderOrReceiverName }}
                >
                  To {message.recipient.user.firstName}
                </StandardText>
                <StandardText
                  style={{ ...ownStyles.messageSenderOrReceiverUsername }}
                >
                  ({message.recipient.username})
                </StandardText>
              </>
            ) : (
              <>
                <StandardText
                  style={{ ...ownStyles.messageSenderOrReceiverName }}
                >
                  From {message.sender.user.firstName}
                </StandardText>
                <StandardText
                  style={{ ...ownStyles.messageSenderOrReceiverUsername }}
                >
                  ({message.sender.username})
                </StandardText>
              </>
            )}
          </View>
          <View style={{ flex: 1 }} />
          <View>
            <StandardText style={{ ...ownStyles.messageTime }}>
              {formatDate(message.createdAt)}
            </StandardText>
          </View>
        </View>
        <StandardText style={{ ...ownStyles.messageContent }}>
          {message.content}
        </StandardText>
      </View>
    ));
  } else if (selectedTab === 3) {
    tabContents = (
      <View style={{}}>
        {composeErrorMessage && (
          <StandardText
            style={{ color: Colors.error, marginBottom: 8, marginTop: 16 }}
          >
            {composeErrorMessage}
          </StandardText>
        )}

        {composeSuccessMessage && (
          <StandardText
            style={{ color: Colors.success, marginBottom: 8, marginTop: 16 }}
          >
            {composeSuccessMessage}
          </StandardText>
        )}

        <View
          style={{
            flexDirection: "row",
            marginBottom: 8,
            alignItems: "center",
          }}
        >
          <TextInput
            style={[
              styleContext.input,
              { fontFamily: "Roboto_400Regular", flex: 1, marginRight: 8 },
            ]}
            editable={!sendingMessage}
            value={composeRecipient}
            placeholder={"Recipient..."}
            onChangeText={(text) => {
              setComposeRecipient(text);
            }}
          />
          <Menu>
            <MenuTrigger>
              <IconButton
                icon={<ProfileIcon size={24} color={Colors.onBackground} />}
              />
            </MenuTrigger>
            <MenuOptions customStyles={ownStyles.recipientMenuOptions}>
              <ScrollView style={ownStyles.recipientMenuScrollView}>
                {availableFriends.map((friend, idx) => (
                  <MenuOption
                    key={idx}
                    customStyles={ownStyles.recipientMenuOption}
                    onSelect={() => setComposeRecipient(friend.username)}
                    text={
                      (friend.user?.firstName || "") +
                      "(" +
                      friend.username +
                      ")"
                    }
                  />
                ))}
              </ScrollView>
            </MenuOptions>
          </Menu>
        </View>

        {/* {availableFriends.map((friend) => (
                <TouchableOpacity
                    key={friend.id}
                    disabled={sendingMessage}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 8,
                        backgroundColor: Colors.background,
                        borderColor: Colors.onBackgroundBorder,
                        borderWidth: 1,
                        borderRadius: 8,
                        padding: 8,
                        paddingLeft: 8,
                        paddingRight: 8,
                    }}
                    onPress={() => setComposeRecipient(friend.username)}
                >
                    <StandardText style={{fontSize: 20, color: Colors.onBackground}}>
                        {friend.user?.firstName}
                    </StandardText>
                    <StandardText style={{fontSize: 20, color: Colors.onBackground3, marginLeft: 8}}>
                        ({friend.username})
                    </StandardText>
                </TouchableOpacity>
            ))} */}

        <TextInput
          style={[
            styleContext.input,
            { fontFamily: "Roboto_400Regular", height: 320 },
          ]}
          editable={!sendingMessage}
          value={composeMessageContent}
          placeholder={"Message..."}
          onChangeText={(text) => {
            setComposeMessageContent(text);
          }}
          multiline={true}
        />

        <LoadingButton
          label={"Send"}
          onPress={handleSendMessagePress}
          showChevron={false}
          disabled={sendingMessage}
          loading={sendingMessage}
        />
      </View>
    );
  }

  return (
    <SafeAreaView
      style={[
        styleContext.safeArea,
        styleContext.container,
        {
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "stretch",
        },
      ]}
    >
      <View style={{ ...ownStyles.blurredSuitsBgContainer }}>
        <Image
          source={{ uri: blurredSuitsBg }}
          style={{ width: 300, height: 306 }}
        />
      </View>

      <View style={{ ...ownStyles.topBar }}>
        <View>
          <StandardText style={{ ...ownStyles.screenTitle }}>
            Messages
          </StandardText>
        </View>
      </View>

      <ScrollView
        ref={containerRef}
        contentContainerStyle={{ height: "100%", justifyContent: "stretch" }}
      >
        <View style={{ ...ownStyles.mainContainer }}>
          <View style={{ ...ownStyles.messagesContainer }}>
            <TabGroup
              selectedTabId={selectedTab}
              tabs={[
                { id: 1, label: "Received" },
                { id: 2, label: "Sent" },
                { id: 3, label: "Compose" },
              ]}
              onTabSelected={(id) => {
                setSelectedTab(id);
              }}
            />
            {tabContents}
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const ownStyles = {
  topBar: {
    width: "100%",
    padding: 20,
    height: 82,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  screenTitle: {
    fontSize: 20,
    weight: 500,
    color: Colors.onBackground4,
  },
  blurredSuitsBgContainer: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  messagesContainer: {
    width: "100%",
    height: "100%",
    maxWidth: 600,
    maxheight: 800,
  },

  message: {
    borderLeftWidth: 2,
    borderLeftColor: Colors.onBackground3,
    padding: 8,
    marginTop: 8,
    marginBottom: 8,
  },

  messageTime: {
    fontSize: 14,
    color: Colors.onBackground3,
  },

  messageSenderOrReceiverName: {
    fontWeight: "bold",
  },
  messageSenderOrReceiverUsername: {
    fontWeight: "bold",
    color: Colors.onBackground3,
    marginLeft: 4,
  },

  messageContent: {},

  recipientMenuScrollView: {
    maxHeight: 300,

    backgroundColor: Colors.background,
    borderRadius: 3,
    borderWidth: 2,
    borderColor: Colors.onBackground,
    boxShadow: "rgb(0 0 0 / 20%) 3px 3px 4px",
  },

  recipientMenuOptions: {
    optionsContainer: {
      backgroundColor: "transparent",
      marginTop: 45,
      boxShadow: "none",
    },
  },

  recipientMenuOption: {
    optionWrapper: {
      backgroundColor: Colors.background,
      borderRadius: 0,
      borderBottomWidth: 2,
      padding: 10,
      borderColor: Colors.onBackground,
    },
    optionText: {
      color: Colors.onBackground,
    },
  },
};

const mapStateToProps = (state) => {
  return {
    accessToken: state.auth.login.accessToken,
    userAccount: state.auth.login.userAccount,
    gamesError: state.viewer.games.error,
    friends: state.viewer.friends.data,
    friendsCursor: state.viewer.friends.cursor,
    friendsLoading: state.viewer.friends.loading,
    subscription: state.auth.profile.subscription,
  };
};

export default connect(mapStateToProps)(MessagesScreen);
