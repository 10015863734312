import React from "react";
import PropTypes from "prop-types";
import {Text, TouchableOpacity, View} from "react-native";
import {ActivityIndicator} from "react-native";
import Colors from "../../styles/colors";
import StandardText from "../standardText/StandardText";
import ChevronIcon from "../../icons/ChevronIcon";
import PlusIcon from "../../icons/PlusIcon";

const LoadingButton = ({
                           onPress,
                           loading,
                           disabled,
                           label,
                           backgroundColor,
                           labelColor,
                           loadingColor,
                           borderColor,
                           padding,
                           minHeight,
                           maxWidth,
                           outlined = false,
                           showChevron = true,
                           showPlus = false,
                           chevronDirection = 0,
                           smallChevron = false,
                           fontSize = 16,
                       }) => {

    onPress = onPress ?? null;
    maxWidth = maxWidth ?? null;
    minHeight = minHeight ?? null;

    const style = {
        backgroundColor: backgroundColor ? backgroundColor : Colors.primary4,
        borderWidth: 0,
        opacity: disabled ? 0.6 : 1,
        padding: padding ? padding : 10,
        marginTop: 8,
        marginBottom: 8,
        borderRadius: 6,
        alignItems: 'center',
        justifyContent: 'center'
    };

    const outlinedStyle = {
        padding: 10,
        marginTop: 8,
        marginBottom: 8,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: Colors.onBackground,
        alignItems: 'center',
    };

    if (maxWidth !== null) {
        style.maxWidth = maxWidth;
    }

    if (minHeight !== null) {
        style.minHeight = minHeight;
    }

    return (
        <TouchableOpacity style={[(outlined ? outlinedStyle : style), {opacity: loading || disabled ? 0.4 : 1}]}
                          onPress={onPress} disabled={loading || disabled} activeOpacity={0.5}>
            {(<StandardText style={{
                color: outlined ? Colors.onBackground : (labelColor ? labelColor : Colors.onPrimary),
                fontSize,
                textAlign: 'center',
                opacity: loading ? 0 : 1
            }} weight={400}>{label}</StandardText>)}
            {loading && (
                <View style={{position: 'absolute'}}>
                    <ActivityIndicator
                        color={outlined ? Colors.onBackground : (loadingColor ? loadingColor : Colors.onPrimary)}/>
                </View>
            )}
            {showChevron &&
            <View style={{position: 'absolute', top: 0, bottom: 0, justifyContent: 'center', right: 20, transform: [{rotate: (chevronDirection * 90) + 'deg'}]}}>
                <ChevronIcon color={Colors.onPrimary} size={smallChevron ? 10 : 20}/>
            </View>}
            {showPlus &&
            <View style={{position: 'absolute', top: 0, bottom: 0, justifyContent: 'center', right: 20}}>
                <PlusIcon color={Colors.onPrimary} size={20}/>
            </View>}
        </TouchableOpacity>
    );
};

LoadingButton.propTypes = {
    onPress: PropTypes.func,
    loading: PropTypes.bool,
    label: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string,
    labelColor: PropTypes.string,
};

export default LoadingButton;
